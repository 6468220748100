.readonly {
  background-color: #eee;
  border: 1px solid #ccc;
  color: #999;
  cursor: not-allowed;
  pointer-events: none;
}

// @media (prefers-color-scheme: dark) {
//   .readonly {
//     background-color: #333;
//     border: 1px solid #222;
//     color: #999;
//   }
// }
